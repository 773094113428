html,
body,
.App,
#root {
  height: 100%;
  min-height: 100%;
}
.white {
  color: #fff;
}
.black {
  color: #000;
}
.uppercase {
  text-transform: uppercase;
}
.word-break-all {
  word-break: break-all;
}
.pointer {
  cursor: pointer;
}
#Footer {
  width: 100%;
  position: relative;
  bottom: 0px;
}
.main-container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#Wrapper {
  width: 100% !important;
  margin: 0 !important;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.img-responsive,
.full-width {
  width: 100%;
}
.hide {
  display: none !important;
}
.nowrap {
  white-space: nowrap;
}
.fit-content {
  width: fit-content;
  width: -moz-fit-content;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.block-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-justify {
  text-align: justify;
}
.f-right {
  float: right;
}
.f-left {
  float: left;
}
.inline-block {
  display: inline-block;
}
.bold {
  font-weight: bold !important;
}
.bolder {
  font-weight: bolder;
}
.normal {
  font-weight: normal;
}
.align-top {
  vertical-align: top;
}
.align-middle {
  vertical-align: middle;
}
.align-bottom {
  vertical-align: bottom;
}
.img-right {
  background-position: right;
}
.img-left {
  background-position: left;
}
.max-width {
  max-width: 100%;
  height: auto;
}
.underline,
a.underline {
  text-decoration: underline;
}
.flex {
  display: flex;
}
.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-50 {
  margin-top: 50px;
}
.mtn-5 {
  margin-top: -5px;
}
.mtn-10 {
  margin-top: -10px;
}
.mtn-15 {
  margin-top: -15px;
}
.mtn-20 {
  margin-top: -20px;
}
.mtn-25 {
  margin-top: -25px;
}
.mtn-30 {
  margin-top: -30px;
}
.mtn-35 {
  margin-top: -35px;
}
.mtn-50 {
  margin-top: -50px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-50 {
  margin-bottom: 50px;
}
.ml-0 {
  margin-left: 0;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-0 {
  margin-right: 0;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-50 {
  margin-right: 50px;
}
.mt-0 {
  margin-top: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.ml-0 {
  margin-left: 0;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.mr-0 {
  margin-right: 0;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.p-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pl-0 {
  padding-left: 0;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-0 {
  padding-right: 0;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-50 {
  padding-right: 50px;
}
.pv-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.po-0 {
  padding-left: 0;
  padding-right: 0;
}
.po-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.po-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.po-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.po-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.po-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.po-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.po-35 {
  padding-left: 35px;
  padding-right: 35px;
}
.po-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.b-radius-0 {
  border-radius: 0;
}
.b-radius-5 {
  border-radius: 5px;
}
.b-radius-10 {
  border-radius: 10px;
}
.b-radius-15 {
  border-radius: 15px;
}
.b-radius-20 {
  border-radius: 20px;
}
.b-radius-50 {
  border-radius: 50px;
}
.spacing-25 {
  margin-top: 25%;
}
.w30 {
  width: 30%;
}
.w70 {
  width: 70%;
}
form input[type="file"] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
form input[type="file"].error ~ .custom-file-upload {
  border: 1px solid red !important;
}
.fix-line-height {
  line-height: 1em;
}
/*.check-block label {
    width: calc(100% - 30px);
	margin: 0;
    z-index: 999;
    position: absolute;
    left: 40px;
    text-align: left;
    top: 6px;
}
.check-block  {
    margin-top: 5px;
	margin-bottom: 5px;
}*/
.check-error {
  display: none;
}
input[type="checkbox"].error ~ .check-error,
input[type="radio"].error ~ .check-error {
  border: 1px solid red;
  position: absolute;
  width: 105%;
  height: 112%;
  top: 0px;
  left: -10px;
  display: block;
  z-index: 0;
}
input[type="checkbox"],
input[type="radio"] {
  z-index: 10;
}
.form-check label {
  margin: 0 0 0 5px;
  z-index: 11;
  text-align: left;
}
textarea,
input,
button,
select,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none !important;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#loading-overlay {
  position: fixed;
  height: 120%;
  width: 100%;
  z-index: 9998;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
#lds-spinner {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 991px) {
  .hide-md {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .check-block label {
    top: 0px;
  }
  .center-mobile {
    text-align: center;
  }
  .hide-mobile {
    display: none;
  }
  .invert-xs {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media only screen and (min-width: 768px) {
  .hide-desktop {
    display: none;
  }
  .f-right-desktop {
    float: right;
  }
  .block-overlay-desktop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .hide-tablet {
    display: none;
  }
}
@media only screen and (max-width: 525px) {
  .hide-small {
    display: none;
  }
  .view-small {
    display: none;
  }
}
@media only screen and (max-width: 524px) {
  .view-small {
    display: inherit;
  }
}

/* to break down col-xs */
.visible-xxs {
  display: none !important;
}
@media (max-width: 525px) {
  .center-xxs {
    text-align: center;
  }
  .col-xxs-9 {
    width: 75%;
  }
  .col-xxs-8 {
    width: 66.666%;
  }
  .col-xxs-7 {
    width: 58.333%;
  }
  .col-xxs-5 {
    width: 41.667%;
  }
  .col-xxs-4 {
    width: 33.333%;
  }
  .col-xxs-3 {
    width: 25%;
  }
  .col-xxs-12 {
    float: none;
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .hidden-xxs {
    display: none !important;
  }
  .visible-xxs {
    display: block !important;
  }
  .invert-xxs {
    display: flex;
    flex-direction: column-reverse;
  }
}
