:root {
  --c-primary-color: #2670ad;
  --c-secondary-color: #707070;
  --c-third-color: #adadad;
  --c-menucolor: #2670ad;
  --c-menuhovercolor: #707070;
  --c--title-color: #2670ad;
  --c-btn-color: #ffffff;
  --c-btn-bgcolor: #2670ad;
  --c-btn-hovercolor: #2670ad;
  --c-btn-hoverbgcolor: #ffffff;
  --c-btn-upload-color: #2670ad;
  --c-btn-upload-bgcolor: #707070;
  --c-input-border: #707070;
  --c-label-color: #889498;
  --c-header-color: transparent;
  --c-header-fixed-color: #e8e8e8;
  --c-footer-color: #ffffff;
  --c-footer-bg-color: #2670ad;
  --c-placeholder-form-color: #707070;
  --c-white-color: #fff;
  --c-heading-color: #515365;
  --c-icon-color: #9a9fbf;
  --c-body-bg: #edf2f6;
  --c-body-color: #2670ad;
  --cc-header-height: 6rem;
  scroll-behavior: smooth;
}
body {
  color: var(--c-body-color);
  background-color: var(--c-body-bg);
  /*	background-image:url(./../images/alter/bg.png);
    background-repeat: no-repeat ;
    background-attachment: scroll ;
    background-position: center top;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/alter/bg.png',sizingMethod='scale');
    -ms-filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/alter/bg.png',sizingMethod='scale');	
*/
  /*background-size: contain;*/
}
/*body.home .main-container{
	color:#4A4A49;    
	background: #D41125;

}*/
#Banner {
  background-image: url(./../../images/alter/bg-banner.svg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center top;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../../images/alter/bg-banner.svg',sizingMethod='scale');
  -ms-filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../../images/alter/bg-banner.svg',sizingMethod='scale');
}
.banner-wrapper {
  /*background-color: #0095D8;*/
}

.main-container {
  overflow: hidden;
} /*
.home .main-container {
    background-color:transparent;
}
*/

.inner-header {
  background-color: var(--c-header-color);
}
.fixed-header .inner-header {
  /*background-image:url(./../images/alter/bg.png);
    background-repeat: no-repeat ;
    background-attachment: scroll ;
    background-position: center bottom;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/alter/bg.png',sizingMethod='scale');
    -ms-filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/alter/bg.png',sizingMethod='scale');	*/
  background-color: var(--c-header-fixed-color);
  box-shadow: 0px 1px 0px 1px #ccc;
}
.box-border-main {
  border: 1px solid var(--c-primary-color);
}

.box-border-secondary {
  border: 1px solid var(--c-secondary-color);
}

.top-border-secondary {
  border-top: 1px solid var(--c-secondary-color);
}

#Footer .copyright {
  background-color: var(--c-footer-bg-color);
  /*background:url(./../images/alter/bg-footer.png) no-repeat top center #3D3E40;*/
  /*background-size: 100%;*/
  /*	box-shadow:0px -3px 0px #0000002E;*/
  /*	border-top: 5px solid #FFF;
	background-size: cover;*/
}

#Footer .copyright,
#Footer .copyright a {
  color: var(--c-footer-color);
}
a,
h2 {
  color: var(--c-secondary-color);
}
h1 {
  color: var(--c-primary-color);
}
a:focus,
a:hover,
a:active {
  color: var(--c-primary-color);
}
#user_messages {
  color: #e3000f;
}
.red {
  color: #e3000f;
}
.lightgray {
  color: #eeeeee;
}
.info-box h2 {
  color: var(--c-secondary-color) !important;
}
.bg-lightgray {
  background-color: #eeeeee;
}
.main-line {
  border-bottom: 1px solid #5b514a;
}
.main-line.input-attachment {
  border-bottom: 1px solid #e3000f;
}
.base-link {
  color: #4a4a49;
}
.main-bg-color {
  background-color: var(--c-primary-color);
}
.main-color {
  color: var(--c-primary-color);
}
.secondary-color {
  color: var(--c-secondary-color);
}
.secondary-bg-color {
  background-color: var(--c-secondary-color);
}
.third-color {
  color: var(--c-third-color);
}
.third-bg-color {
  background-color: #e7e7e7;
}
.body-bg-color {
  background-color: var(--c-body-bg);
}

.black {
  color: #000 !important;
}
.nav-text {
  color: var(--c-menucolor);
}
.nav-link:hover .nav-text,
.nav-link:active .nav-text,
.nav-link:focus .nav-text,
.active .nav-link .nav-text {
  color: var(--c-menuhovercolor);
}

::placeholder {
  color: var(--c-label-color);
}

.inputs:active,
.inputs:focus {
  box-shadow: inset 0px 0 0 2px var(--c-primary-color);
}
.inputs,
.inputs {
  border: 1px solid var(--c-input-border);
  color: var(--c-placeholder-form-color);
  background: var(--c-white-color);
}
.box-totclienti ::placeholder,
.box-omaggi ::placeholder,
.box-totclienti .inputs,
.box-omaggi .inputs {
  color: var(--c-primary-color);
}

label {
  color: var(--c-label-color);
}

form .custom-file-upload {
  background-color: var(--c-btn-upload-bgcolor);
  color: var(--c-btn-upload-color);
  border: 1px solid var(--c-btn-upload-bgcolor);
}
input[disabled] {
  color: var(--c-primary-color) !important;
  background: #fff;
}

.button-standard {
  color: var(--c-btn-color);
  background: var(--c-btn-bgcolor);
  border: 1px solid var(--c-btn-bgcolor);
  /*box-shadow:0px 0px 12px #00000029;*/
}

.button-inverse {
  color: var(--c-white-color);
  background-color: var(--c-secondary-color);
  border: 1px solid var(--c-secondary-color);
  /*box-shadow:0px 3px 0px #5f5f5f4a;*/
}

.btn:hover {
  color: var(--c-btn-bgcolor);
  background-color: var(--c-btn-color);
}

.button-inverse:hover {
  background-color: var(--c-white-color);
  color: var(--c-secondary-color);
}
@media (max-width: 1440px) {
  /*
#Banner{
	background-image:url(./../images/alter/bg-banner-lg.png);
    background-repeat: no-repeat ;
    background-attachment: scroll ;
    background-position: center top;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/alter/bg-banner-lg.png',sizingMethod='scale');
    -ms-filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='./../images/alter/bg-banner-lg.png',sizingMethod='scale');	
}*/
}
@media (max-width: 1439px) {
}
@media (max-width: 991px) {
}
@media (max-width: 767px) {
  #Banner {
    background-size: cover;
  }
  .banner-wrapper {
    padding-top: 5rem;
  }

  #overlay-menu-button span {
    background-color: var(--c-menucolor);
  }
  #overlay-menu-button span,
  #overlay-menu-button span:before,
  #overlay-menu-button span:after {
    background-color: var(--c-menucolor);
  }
  #overlay-menu-input:checked ~ #overlay-menu-button:hover span,
  #overlay-menu-input:checked ~ #overlay-menu-button:hover span:before,
  #overlay-menu-input:checked ~ #overlay-menu-button:hover span:after {
    background: var(--c-menucolor);
  }
  #overlay-menu-input:checked ~ #overlay-menu-button:hover span,
  #overlay-menu-input:checked ~ #overlay-menu-button span {
    background: transparent;
  }
  #overlay-menu {
    background-color: var(--c-header-fixed-color);
  }
  #overlay-menu ul li a {
    color: var(--c-menucolor);
  }
  #overlay-menu ul li a:hover {
    color: var(--c-menuhovercolor);
  }
}
@media (max-width: 575px) {
}
