@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Bold.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/RobotoCondensed-Regular.woff2") format("woff2"),
    url("../../fonts/RobotoCondensed-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

a.nav-link.active {
  pointer-events: none;
}

a.nav-link.active span.nav-text {
  color: var(--c-menuhovercolor);
}

img.obj-fit-contain {
  object-fit: contain;
}

html {
  font-size: 20px;
}
body {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.1rem; /*22px*/
  font-weight: 500;
  line-height: 1.2;
}
#user_messages {
  max-width: 400px;
  margin: 0 auto;
  line-height: 1.2em;
}
.no-border {
  border: 0 !important;
}
.user-info {
  top: 60%;
  position: absolute;
}
.full-width {
  width: 100%;
}
.field-icon {
  float: right;
  right: 5px;
  top: 10px;
  position: absolute;
  z-index: 2;
  width: 30px;
  height: 30px;
  /* background: url(./../images/alter/eye.png) no-repeat center center; */
  background-size: cover;
  cursor: pointer;
}
.field-icon.btn-eye-off {
  /* background: url(./../images/alter/eye-off.png) no-repeat center center; */
  background-size: cover;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-right: 1.3rem !important;
  padding-left: 1.3rem !important;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}
@media (min-width: 1440px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}
/*COOKIES BANNER*/
.cookiebanner {
  background-color: #e30613 !important;
}
.cookiebanner,
.cookiebanner a,
.cookiebanner label {
  color: #fff !important;
}
.cookiebanner a {
  text-decoration: underline !important;
}

.cookiebanner a:hover {
  color: #d2d2d2 !important;
}
.button-cookies,
.cookiebanner input[type="button"] {
  background-color: #ffffff !important;
  color: #e30613 !important;
  border: none !important;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid #fff !important;
}
.button-cookies:hover,
.cookiebanner input[type="button"]:hover {
  background-color: #e30613 !important;
  color: #ffffff !important;
}
/*END COOKIES BANNER*/

.main-container {
  overflow-x: hidden;
}
.inner-header {
  transition: ease-in-out 0.2s;
  height: 6rem;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 99;
  /*	background:url(./../images/alter/bg-menu.png) bottom center no-repeat;*/
  /*background-size: cover;*/
}
#Banner {
  /*margin-top: 5rem;*/
  /*	margin-bottom: 30px;*/
  overflow: hidden;
}
/*.home #Banner {
padding-top: 140px;
}*/
.top-footer {
  z-index: 0;
}

/*STICKY HEADER*/
#Header.fixed-header {
  position: fixed;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  z-index: 9990; /*height:67px;*/
  top: 0;
}
input[type="radio"] ~ label {
  display: inline;
  margin-left: 0.2rem;
}
.logo-main {
  height: 100%;
  width: auto;
  transition: all 0.2s ease-in-out;
  padding: 20px 0;
}

#Header.fixed-header .logo-main {
  height: 100%;
  padding: 0.5rem 0;
}
.inner-header {
  /*box-shadow: 0px 1px 0px 1px #ccc;*/
}

#Header.fixed-header .inner-header {
  height: 3.5rem;
  top: 0;
  /*box-shadow: 0px 1px 0px 1px #ccc;*/
}
.logo-bar,
.logo,
.menu_box {
  transition: 0.2s ease-in-out;
}

/*END STICKY HEADER*/

/*MENU*/
.menu_wrapper {
  margin: 0 auto;
  /*margin-top: 20px;*/
  float: right;
}

.nav-link {
  font-size: 1.25rem;
  font-weight: normal;
  font-family: "Roboto Condensed";
  padding: 0em 1.2em !important;
}
.fixed-header .nav-link {
  /*    padding: 0em 1em!important;
	font-size: .85rem;*/
}
.nav-link,
.nav-link:hover,
.nav-link:active,
.nav-link:focus {
  /*color: #FFF;*/
  text-decoration: none;
}

.navbar-nav {
  list-style: none;
  padding: 0;
}
.navbar {
  padding: 0;
}
.menu_box {
  margin: 0 auto;
  width: fit-content;
}
.navbar-toggle .icon-bar {
  background-color: #007cbb;
}
.navbar-toggle {
  margin-right: 8px;
}
/*END MENU*/
/*FONT*/
.font-sdt,
.font-std {
  font-family: "Roboto Condensed", sans-serif;
}
.font-special {
  /*font-family: 'HouseMovements', cursive;*/
}
.font-base {
  font-size: 1rem;
}
.font-xxs {
  font-size: 12px; /**/
}
.font-xs {
  font-size: 0.7rem; /*14px*/
}
.font-s {
  font-size: 0.8rem; /*16*/
  line-height: 1.3;
}
.font-m {
  font-size: 1.25rem; /**/
}
h3 {
  font-size: 1.1rem; /*22*/
}
.font-ml {
  font-size: 1.2rem; /**/
}
.font-l,
h2 {
  font-size: 1.75rem; /*35*/
}

.font-xl,
h1 {
  font-size: 2.2rem;
}
h2 {
  min-height: 20px;
}
.font-xxl {
  font-size: 4.55rem;
  /*line-height: 1em;*/
}
h3 {
  font-size: 1rem;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: "Roboto Condensed", sans-serif;
}
h1 {
  font-weight: bold;
}
.info-box h2 {
  font-size: 2.35rem;
}
.info-box p {
  font-size: 1.1rem;
  line-height: 1.7rem;
}

.no-underline,
.btn-box a {
  text-decoration: none !important;
}
/*END FONT*/
/*COLOR*/
/*MAIN COLOR #007cbb blu*/
/*SECONDARY COLOR  #b3b3b3 #grigio*/
/**-#63513d marrone*/
.block-middle h2 {
  border-bottom: 2px solid var(--c-primary-color);
  display: inline-block;
  padding-bottom: 10px;
}
.search-box {
  background: #e8e8e8;
  padding: 20px 20px;
  margin-top: 30px;
}

.border-main {
  box-shadow: 0px 0px 5px 6px #007cbb;
}
.border-secondary {
  box-shadow: 0px 0px 5px 6px /*#CEE7DE*/ #fff;
}
a {
  text-decoration: underline;
}
.btn a:focus,
.btn a:hover,
.btn a:active {
  color: #ffffff;
}

.btn a,
a.btn,
button.btn {
  text-decoration: none;
  /*border:1px solid #b3b3b3;*/
}

.btn {
  /*box-shadow: 0px 3px 0px #003121;*/
  padding: 12px 2.8rem;
  font-weight: normal;
  font-size: 1rem;
  white-space: inherit;
}
.overlay-left {
  left: -14rem;
}
.richiesta .btn {
  max-width: 255px;
}

input.product:checked ~ label {
  opacity: 0.7;
}

.btn-large .btn {
  padding: 2px 40px;

  /*box-shadow: 4px -4px 0px #273069;*/
}
.btn-small .btn {
  padding: 9px;
  width: 100px;

  /*box-shadow: 4px -4px 0px #273069;*/
}

.btn-box {
  display: inline-block;
}
.data-ora {
  border: 1px solid #007cbb;
  border-radius: 5px;
  padding: 15px;
  color: grey;
}
.btn-shadow {
  /*		box-shadow:8px -8px 0px #7391b2;*/
}

.btn:hover {
  /*box-shadow: 0px 4px 0px #CEE7DE;*/
}
/*END COLOR*/

.spacing-text {
  margin: 140px auto;
}

/*PAGINATION*/
.paginationjs .paginationjs-pages li.active > a {
  /*background: #e6007e!important;*/
  color: #51312d !important;
  background: #fff !important;
  /*line-height: 29px!important;*/
}
.paginationjs .paginationjs-pages li > a:hover {
  background: #fff !important;
}

.paginationjs .paginationjs-pages li > a {
  background: 0 0;
  font-size: 17px !important;
  min-width: 21px !important;
  /*font-family:'benguiat_frisky', sans-serif;*/
  padding: 0 5px;
  text-decoration: none;
}
.paginationjs .paginationjs-pages li {
  border: 0 !important;
}

.paginationjs .paginationjs-pages {
  float: none !important;
}

.paginationjs .paginationjs-pages ul {
  float: none !important;
  display: inline-flex;
  list-style: none;
  padding: 0;
}

.border-bottom {
  border-bottom: 1px solid lightgray;
}

/*FORM*/
.form-small .inputs,
.form-small .btn {
  width: 100%;
  max-width: 500px;
  margin: 5px auto;
}
form .custom-file-upload {
  display: inline-block;
  padding: 15px 10px;
  cursor: pointer;
  font-weight: normal;
  margin: 5px 0;
  border-radius: 0px;
  box-shadow: 0px 3px 0px #00000029;
  max-width: 360px;
}
#form_receipt .scontrino input[type="text"] {
  text-align: center;
  height: 38px;
}
.scontrino {
  overflow: hidden;
}
form input ~ span {
  margin: 2px;
}
/*#receipt_number{
	width: 100%!important;   
	max-width: 255px;	
}
#form_receipt  .scontrino .data input[type="text"] {
    width: 26%!important;
}*/

#form_receipt .files {
  max-width: 375px;
  margin: 0 auto;
}
.flex {
  display: flex;
  justify-content: center;
}
.flex .block {
  -js-display: flex;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 5px 15px;
}

.inputs,
form .inputs {
  width: 100%;
  border-radius: var(--c-btn-radius);
  text-align: center;
  min-width: 62px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 1rem;
  height: 1.95rem;
}
.AreaPersonaleSuperuser .inputs {
  height: 2.8rem;
  max-width: 15.55rem;
}
.box-cercaclienti,
.box-inviapunti {
  max-width: 15.55rem;
  margin: 0 auto;
}
.box-ap-clienti .btn {
  height: 1.95rem !important;
  width: 7.15rem !important;
  padding: 0 !important;
  display: inline-block;
  min-width: auto;
}
.form-check-input[type="checkbox"] {
  border-radius: 100% !important;
  width: 23px;
  height: 23px;
}
.inputs.full-width {
  box-shadow: 0px 0px 23px #00000029;
  line-height: 40px;
}
form .small-input {
  line-height: 20px;
  max-width: 320px;
}
form .ricerca .inputs,
.small-inputs {
  width: 70%;
}
form .ricerca-full .inputs {
  width: 100%;
  color: #005c23;
  font-weight: 500;
}
form .ricerca .inputs,
.small-inputs {
  width: 70% !important;
}

.btn-flat {
  padding: 7px;
  box-shadow: none;
  line-height: 1em;
  font-size: 0.8em;
}
.cerca .btn {
  padding: 2px 25px;
}

.btn-large .btn {
  line-height: 40px;
}
form select {
  padding: 3px;
}
.tipo-premio {
  width: 10rem !important;
}
form .inputs.error {
  border: 1px solid red !important;
}
.form-small {
  max-width: 430px;
  margin: 0 auto;
}
form .btn {
  padding: 0.375rem 2.8rem !important;
}
/* label {
  font-size: 1rem; */
/*	font-family: 'Open Sans', sans-serif;*/
/*font-weight: bold;    */
/* text-align: center;
  width: 100%;
  margin: 0.75rem 0px 0.25rem 0px;
  display: block; */
/* } */

form .bg-black label {
  font-weight: normal;
}
/*.dati-acquisto>div{margin-top:33px;}*/
.check-label label,
.radio-label label {
  margin: 0;
  text-align: left;
  display: initial;
  cursor: pointer;
  z-index: 999;
}
.check-block > input,
.check-block > label {
  display: initial;
  z-index: 12;
  position: relative;
}
.check-block > input {
  margin-right: 5px;
}

.error ~ .check-label {
  border: 1px solid red;
  margin-left: -25px;
  padding-left: 25px;
}

input[value="1"].error ~ .check-error,
input[value="F"].error ~ .check-error {
  border-right: 0;
}
input[value="0"].error ~ .check-errorm,
input[value="M"].error ~ .check-error {
  border-left: 0;
}
#receipt_file_front_name {
  color: #fff;
  text-align: center;
}
input[type="radio"],
input[type="radio"] ~ label {
  position: relative;
  z-index: 12;
}
.error ~ .radio-label label {
  color: red;
}
.box-premi {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.box-premio {
  margin-bottom: 3.25rem !important;
  box-shadow: 0px 0px 20px #00000022;
}
/**/
.swal2-modal {
  max-width: 370px;
  padding: 1.5em 0;
}

.swal2-html-container {
  font-size: 1.75rem;
  text-align: center;
}
.swal-button-container {
  width: 90%;
  margin: 0;
}
.swal2-styled {
  width: 80%;
  max-width: 12rem;
  margin: 1rem;
  border-radius: 0 !important;
  font-size: 1rem;
}

.swal2-confirm {
  background-color: var(--c-primary-color) !important;
  color: var(--c-white-color) !important;
}
.swal2-confirm:hover,
.swal2-confirm:active,
.swal2-confirm:focus {
  background-color: var(--c-primary-color) !important;
  color: var(--c-white-color) !important;
  opacity: 0.8;
}
.swal2-cancel {
  background-color: var(--c-secondary-color) !important;
  color: var(--c-white-color) !important;
}
.swal2-cancel:hover,
.swal2-cancel:active,
.swal2-cancel:focus {
  background-color: var(--c-secondary-color) !important;
  color: var(--c-white-color) !important;
  opacity: 0.8;
}
.swal-footer {
  text-align: center;
  position: relative;
  min-height: 10rem;
}

/**/

@-webkit-keyframes c-error {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
}

@keyframes c-error {
  0%,
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    transform: translateX(5px);
  }
}
@-moz-keyframes c-error {
  0%,
  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -moz-transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
    -moz-transform: translateX(-5);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(5px);
    -ms-transform: translateX(5px);
    transform: translateX(5px);
    -moz-transform: translateX(5);
  }
}

form .inputs.error {
  border: 2px solid red !important;
  animation: c-error 1.5s linear;
  -webkit-animation: c-error 1.5s linear;
  -moz-animation: c-error 1.5s linear;
  animation-iteration-count: 3;
}
form input[type="file"].error ~ .custom-file-upload,
form input[type="radio"].error ~ label,
.prodotto.error ~ label {
  border: 2px solid red !important;
  animation: c-error 1.5s linear;
  -webkit-animation: c-error 1.5s linear;
  -moz-animation: c-error 1.5s linear;
  animation-iteration-count: 3;
}

.box-omaggi {
  background-color: #d7d7d7;
}
/*TOOLTIP*/
.check-block {
  margin: 0 auto;
}
.box-header {
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  height: 6em;
}
.box-header p,
.box-header h3 {
  line-height: 5em;
}
.special-error {
  border: 2px dashed red;
  color: red;
  margin: 50px auto;
  padding: 10px;
}
.card-small .line {
  max-width: 140px;
  border-bottom: 2px solid gray;
  margin: 0 auto;
  width: 90%;
}
.line-small {
  border-bottom: 1px solid gray;
  width: 50%;
  min-width: 200px;
  margin: 10px auto;
}
.linea {
  border-bottom: 1px solid #bfbfbf;
}
.btn-style {
  background-color: #eeeeee;

  line-height: 2em;
}
/*END FORM*/

.btn-radius {
  border-radius: var(--c-btn-radius);
}
.button-standard {
  line-height: 1em;
  min-width: 180px;
  font-family: "Roboto Condensed", sans-serif;
  /*font-weight: bold;*/
}
form .button-standard {
  line-height: 1.1;
}
/*OVERLAY*/
.loading-overlay {
  background: #214c9bf2;
}
.loading-overlay .lds-spinner {
  margin-top: 100px;
}
/*END OVERLAY*/
/*FOOTER*/
#Footer {
  overflow: hidden;
}
#Footer .copyright {
  /*background:url(./../images/alter/bg-footer.png) no-repeat top center;*/
  /*background-size: 100%;*/
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  line-height: 1.4;
  padding: 25px 0;
  overflow: hidden;
  line-height: 1rem;
  margin-top: -2px;
  z-index: 1;
}
#Footer .copyright {
  margin: 0;
  /*	font-family: 'Open Sans', sans-serif;*/
}
.card {
  display: inherit;
  margin: 0 auto;
  padding: 1rem;
}
.semibold {
  font-weight: 500;
}
.display-contents {
  display: contents !important;
}
/*END FOOTER*/
#Tabella-Dati th:nth-child(1),
#Tabella-Dati td:nth-child(1) {
  width: 6rem;
}
#Tabella-Dati th:nth-child(4),
#Tabella-Dati td:nth-child(4) {
  min-width: 10rem;
}
#Tabella-Dati th,
#Tabella-Dati td {
  vertical-align: middle;
}
#Tabella-Dati p {
  color: var(--c-primary-color);
  font-size: 1rem;
}
/**/

.vb-center-h {
  margin-top: -100px !important;
}

@media (max-width: 1440px) {
  /*.inner-header {
		height: 100px;
	}*/
}
@media (max-width: 1200px) {
  .inner-header {
    height: 6rem;
  }

  html {
    font-size: 19px;
  }
  /*.font-xs {
    font-size: 0.90rem;
}*/
  /*#receipt_number {    
		max-width:210px;
	}*/
  /*.logo-main {    
		height: 90px;
	}*/
}
@media (max-width: 1023px) {
  /* 	.block-top {
    margin-top: 75px;
}*/
}
@media (max-width: 991px) {
  /*	#Footer{
		background-size: cover!important;
	}*/
  /*body {
    line-height: 1.6rem;
}*/

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
  }
  html {
    font-size: 18px;
  }
  .dettaglio-partecipazione .align-left {
    text-align: center;
  }
  .logo-main {
    /*height:25px;*/
  }
  .logo-bar {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .nav-text {
    transition: all 0.2s;
  }
  /*#Header.fixed-header .nav-text {    font-size: 0.8em;}*/
}

@media (max-width: 767px) {
  body {
    background-size: 180%;
  }
  #vediDettagliFatturato {
    font-size: 2rem;
    position: relative;
    padding: 0 !important;
    text-align: center;
  }
  .user-info {
    top: 90%;
  }
  .full-width-sm {
    width: 100%;
  }
  html {
    font-size: 18px;
  }

  .btn-large .btn {
    font-size: 1.55em;
  }
  .menu_box {
    transition: 0s;
  }
  h1 {
    font-size: 1.7em;
  }
  .spacing-text {
    margin: 80px auto;
  }
  /* form label {
    margin-top: 25px;
  } */
  .logo-bar {
    float: left;
  }
  /*MENU*/
  .navbar-fixed-top-mobile {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
  }
  #navbarNavDropdown {
    background: #3a3a3aed;
    position: inherit;
    top: 0px;
    left: 0;
    height: 100vh;
  }
  .nav-link {
    padding: 30px 5px !important;
  }
  .nav-link .nav-text {
    line-height: 2em;
  }
  .navbar-nav {
    margin: 0;
  }
  #overlay-menu-button {
    /*position: absolute;*/
    right: 0.3em;
    top: 1em;
    padding: 10px;
    z-index: 5;
    cursor: pointer;
    user-select: none;
  }
  #overlay-menu-button span {
    height: 4px;
    width: 35px;
    border-radius: 2px;
    /*	background-color: #63513d;*/
    position: relative;
    display: block;
    transition: all 0.2s ease-in-out;
  }
  #overlay-menu-button span:before {
    top: -10px;
    visibility: visible;
  }
  #overlay-menu-button span:after {
    top: 10px;
  }
  #overlay-menu-button span:before,
  #overlay-menu-button span:after {
    height: 4px;
    width: 35px;
    border-radius: 2px;
    position: absolute;
    content: "";
    transition: all 0.2s ease-in-out;
  }
  #overlay-menu-input {
    display: none;
  }

  #overlay-menu-input:checked ~ #overlay-menu {
    visibility: visible;
  }
  #overlay-menu-input:checked ~ #overlay-menu-button span:before {
    transform: rotate(45deg) translate(7px, 7px);
    opacity: 1;
  }
  #overlay-menu-input:checked ~ #overlay-menu-button span:after {
    transform: rotate(-45deg) translate(7px, -7px);
  }
  #overlay-menu-input:checked ~ #overlay-menu-button {
    position: absolute;
  }

  #overlay-menu {
    height: 100vh;
    width: 100vw;
    z-index: 2;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
  }
  #overlay-menu.active {
    visibility: visible;
  }
  #overlay-menu ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    width: 100vw;
    padding-left: 0;
    list-style-type: none;
  }
  #overlay-menu ul li {
    /*padding: 1em;*/
    height: 12vh;
  }
  #overlay-menu ul li a {
    text-decoration: none;
    font-size: 1.4rem;
  }
  .menu_inner {
    width: 100vw;
    height: 100vh;
  }
  /*END MENU*/
}
@media only screen and (max-width: 575px) {
  .font-xxl {
    font-size: 3.5rem;
  }
  .font-ml {
    font-size: 1.3rem;
  }
  .vu-center h1 {
    font-size: 4.5rem;
  }
  .vu-center h2 {
    font-size: 1.45rem;
  }
  .vu-center h3 {
    font-size: 1.2rem;
  }
  .vu-center h4 {
    font-size: 1rem;
  }
  .button-standard {
    min-width: 150px;
  }
  /*html {    font-size: 17px;}*/
  .logo-main {
    /*height: 33px;*/
  }
  #overlay-menu-button {
    /*top: 0.3em;*/
  }
  #Header.fixed-header .logo-main {
    /*height: 20px;*/
  }
}
@media only screen and (max-width: 420px) {
  html {
    font-size: 17px;
  }
}
@media only screen and (max-width: 320px) {
}
